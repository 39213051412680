import React from 'react';

function TvTest() {
  return (
    <div className="Tv">
      <iframe 
        src="https://app.viloud.tv/player/embed/channel/50fe54fa5dc1bb53e279c43aeada5753?autoplay=1&volume=1&controls=1&title=1&share=0&open_playlist=0&random=0"
        frameBorder="0"
        allow="autoplay"
        allowFullScreen  
      />
    </div>
  );
}

export default TvTest;
