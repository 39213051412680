import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import TwitchAvatar from '../components/TwitchAvatar';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function TwitchListItem({user}) {
  const classes = useStyles();

  return (
      <ListItem button>
        <ListItemAvatar>
          <TwitchAvatar user={user} />
        </ListItemAvatar>
        <ListItemText primary={user.display_name} secondary={user.title} />
      </ListItem>
      
  );
}
