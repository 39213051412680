import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';

import TwitchListItem from '../components/TwitchListItem';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: "#9e9e9e",
  },
}));

const TWITCH_CLIENT_ID = 'p3zk1xvv4pvk7e6rsezv9lng596nan';

export default function TwitchList({twitchToken}) {

  const [users, setUsers]=useState([]) //initialize your state here

  function getTwitchUsers() {
    const storedUsers = sessionStorage.getItem('twitchUsers');
    if (storedUsers) {
        return setUsers(JSON.parse(storedUsers));
    }

    const headers = {
      'Authorization': `Bearer ${twitchToken}`,
      'Client-Id': `${TWITCH_CLIENT_ID}`,
    }
    const fetchData = async() => {

      const [alanahResponse, bruceResponse, jacobResponse, jonsmiffResponse, lawdoggResponse, whatashowResponse] = await Promise.all([
        fetch ('https://api.twitch.tv/helix/search/channels?query=charalanahzard&first=1', {
          headers: headers,
        }),
        fetch('https://api.twitch.tv/helix/search/channels?query=brucegreene&first=1', {
          headers: headers,
        }),
        fetch('https://api.twitch.tv/helix/search/channels?query=jacobful&first=1', {
          headers: headers,
        }),
        fetch('https://api.twitch.tv/helix/search/channels?query=jonsmiff&first=1', {
          headers: headers,
        }),
        fetch('https://api.twitch.tv/helix/search/channels?query=sirlarr&first=1', {
          headers: headers,
        }),
        fetch('https://api.twitch.tv/helix/search/channels?query=whatashow&first=1', {
          headers: headers,
        })
      ]);

      const alanah = await alanahResponse.json();
      const bruce = await bruceResponse.json();
      const jacob = await jacobResponse.json();
      const jonsmiff = await jonsmiffResponse.json();
      const lawdogg = await lawdoggResponse.json();
      const whatashow = await whatashowResponse.json();

      let newUsers = [];
      newUsers.push(alanah.data[0]);
      newUsers.push(bruce.data[0]);
      newUsers.push(jacob.data[0]);
      newUsers.push(jonsmiff.data[0]);
      newUsers.push(lawdogg.data[0]);
      newUsers.push(whatashow.data[0]);

      sessionStorage.setItem('twitchUsers', JSON.stringify(newUsers));

      return setUsers(newUsers);
    }
    fetchData();
  };

  useEffect(() => {
    getTwitchUsers(setUsers);
  }, []);

  const classes = useStyles();

  function renderTwitchList() {
    return (
      <List 
        className={classes.root}
        subheader={
          <ListSubheader className="twitch-subheader" component="div" id="nested-list-subheader">
            Twitch Streams
          </ListSubheader>
        }
      >
        {users.map((user, index) => (
          <a href={`https://www.twitch.tv/${user.display_name}`} target="_blank">
            <TwitchListItem user={user} />
            {index < users.length ? <Divider variant="inset" component="li" /> : null}
          </a>
        ))}
    </List>
    );
  };

  let content;
  if (users && users.length) {
    content = renderTwitchList();
  } else {
    content = (
      <div>
        NO TWITCH USERS FOUND
      </div>
    )
  }

  return (
    content
  );
}
