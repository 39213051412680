import React from 'react';

import Home from './pages/Home';
import TvTest from './pages/TvTest';

import {
  Switch,
  Route
} from "react-router-dom";

function App() {
  return (
    <div className="Routes">
      {/*
        A <Switch> looks through all its children <Route>
        elements and renders the first one whose path
        matches the current URL. Use a <Switch> any time
        you have multiple routes, but you want only one
        of them to render at a time
      */}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/tv-test" component={TvTest} />
      </Switch>
    </div>
  );
}

export default App;
