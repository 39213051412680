import React, { useState, useEffect } from 'react';

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from "firebase/app";
import "firebase/database";

import ClientOAuth2 from "client-oauth2";

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import '../App.css';
import YouTubeFrame from '../components/YouTubeFrame';
import TwitchListDrawer from '../components/TwitchListDrawer';
import AuthorInfoDrawer from '../components/AuthorInfoDrawer';
import SiteInfoDrawer from '../components/SiteInfoDrawer';
import DisclaimerBar from '../components/DisclaimerBar';

import { getRandomVideo } from '../utils';

function Home() {

  const CURRENT_TWITCH_ACCESS_TOKEN = 'h7oc40dlm8ly6mt411301jwox1x83x'

  const twitchAuth = new ClientOAuth2({
    clientId: 'p3zk1xvv4pvk7e6rsezv9lng596nan',
    clientSecret: '2kwgf9jenr2e6xpbptn9bdr38wdxgm',
    accessTokenUri: 'https://id.twitch.tv/oauth2/token',
    authorizationUri: 'https://id.twitch.tv/oauth2/authorize',
    redirectUri: 'https://funahustv.com/twitch_auth',
    query: {
      client_id: 'p3zk1xvv4pvk7e6rsezv9lng596nan',
      client_secret: '2kwgf9jenr2e6xpbptn9bdr38wdxgm'
    }
  });

  // Function to scrape YouTube playlist and store videoIds
  // function saveVideoIds(nextPageToken = null) {
  //   const API_KEY = '';

  //   // playlistItem url
  //   let url;
  //   if (nextPageToken) {
  //     url = `https://www.googleapis.com/youtube/v3/playlistItems?part=contentDetails&maxResults=50&pageToken=${nextPageToken}&playlistId=UUboMX_UNgaPBsUOIgasn3-Q&key=${API_KEY}`;
  //   } else {
  //     url = `https://www.googleapis.com/youtube/v3/playlistItems?part=contentDetails&maxResults=50&playlistId=UUboMX_UNgaPBsUOIgasn3-Q&key=${API_KEY}`;
  //   }
  //   debugger;
  //   fetch(url, { 
  //     method: 'GET', 
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   })
  //   .then((response) => response.json())
  //   .then((data) => {
  //     console.log('Success:', data);

  //     data.items.forEach(item => {
  //       // Get a key for a new Post.
  //       const newVidKey = firebase.database().ref().child('videos').push().key;
  //       // debugger;
  //       firebase.database().ref('videos/' + newVidKey).set({
  //         videoId: item.contentDetails.videoId
  //       });
  //     })

  //     debugger;
  //     const pageToken = data.nextPageToken;
  //     if (pageToken) saveVideoIds(pageToken);
  //   })
  //   .catch((error) => {
  //     console.error('Error:', error);
  //   });
  // }

  const [videoId, setVideoId]=useState(0); //initialize your state here
  const [twitchToken, setTwitchToken]=useState(CURRENT_TWITCH_ACCESS_TOKEN);

  function getVideoId() {
    let videos;
    // Get saved data from sessionStorage
    const storedVideos = sessionStorage.getItem('storedVideos');
    if (!storedVideos) {
      firebase.database().ref('/videos/').once('value').then(function(snapshot) {
        videos = snapshot.val();

        // Save data to sessionStorage
        sessionStorage.setItem('storedVideos', JSON.stringify(videos));

        const video = getRandomVideo(videos);
        setVideoId(video.videoId);
        console.log(video)
      });
    } else {
      const video = getRandomVideo(JSON.parse(storedVideos));
      setVideoId(video.videoId);
      console.log(video)
    }
  }

  function getTwitchToken() {
    // Validate Current Access Token
    const myRequest = new Request('https://id.twitch.tv/oauth2/validate', {
      method: 'GET',
      headers: {
        Authorization: 'OAuth ' + CURRENT_TWITCH_ACCESS_TOKEN
      },
      mode: 'cors',
      cache: 'default',
    });

    fetch(myRequest)
      .then(response => response.json())
      .then(data => {
        // if current access token is invalid, get new one
        if (data.status === 401) {
          twitchAuth.credentials.getToken()
          .then(function (user) {
            setTwitchToken(user.data.access_token);
          })
        }
      })
  }

  useEffect(() => {
    // Set the configuration for your app
    // TODO: Replace with your project's config object
    const config = {
      apiKey: "AIzaSyANrcGcG1YM9pzL-c5r4DgaRw-sYQkEkSc",
      authDomain: "funhaustv-270301.firebaseapp.com",
      databaseURL: "https://funhaustv-270301.firebaseio.com/",
      storageBucket: "bucket.appspot.com"
    };
    firebase.initializeApp(config);    
    // saveVideoIds();

    getVideoId(setVideoId);

    //twitch auth via client credentials
    getTwitchToken(setTwitchToken);
  }, []);

  console.log("App videoId:", videoId);

  return (
    <div className="App">
      <header className="App-header">
        <div className="title-container">
          <h2>FunhausTV</h2>
          <TwitchListDrawer twitchToken={twitchToken} />
        </div>
        <YouTubeFrame videoId={videoId} getVideoId={getVideoId} />
        <div className="info-buttons">
          <AuthorInfoDrawer />
          <Button
            className="skip-button"
            variant="contained"
            color="default"
            size="large"
            onClick={getVideoId}
            endIcon={<Icon>skip_next</Icon>}
          >
            Skip
          </Button>
          <SiteInfoDrawer />
        </div>
      </header>
      <DisclaimerBar />
    </div>
  );
}

export default Home;
