import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import TwitchList from '../components/TwitchList';


const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    height: '100%',
    width: 'auto',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#282c34',
  },
});

export default function TwitchListDrawer({twitchToken}) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const fullList = side => (
    <div
      className={classes.fullList}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <TwitchList twitchToken={twitchToken} />
    </div>
  );

  return (
    <div className="info-drawer">
      <Button variant="outlined" size="large" onClick={toggleDrawer('right', true)}>Who's live?</Button>
      <Drawer className="info-open twitch-drawer" anchor="right" open={state.right} onClose={toggleDrawer('right', false)}>
        {fullList('right')}
      </Drawer>
    </div>
  );
}
